// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgDollarCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4.75a.75.75 0 0 1 .75.75v1.13c1.049.177 2.098.657 2.78 1.34a.75.75 0 1 1-1.06 1.06c-.529-.528-1.541-.946-2.49-.972-.556-.015-.993.108-1.272.32-.248.187-.458.508-.458 1.122 0 .255.057.424.13.547.077.128.197.25.382.367.4.255.966.416 1.685.612l.057.016c.646.176 1.427.389 2.04.778.33.21.64.488.864.864q.341.571.342 1.316c0 1.102-.487 1.942-1.244 2.472-.514.36-1.128.562-1.756.637V18.5a.75.75 0 1 1-1.5 0v-1.435c-1.093-.19-2.18-.722-2.85-1.615a.75.75 0 0 1 1.2-.9c.471.628 1.425 1.055 2.427 1.092.654.023 1.23-.126 1.619-.399.359-.25.604-.634.604-1.243a1.03 1.03 0 0 0-.13-.547 1.1 1.1 0 0 0-.382-.367c-.4-.255-.966-.416-1.685-.612l-.057-.016c-.645-.176-1.427-.389-2.04-.778-.33-.21-.64-.488-.864-.864A2.5 2.5 0 0 1 8.75 9.5c0-1.017.38-1.809 1.053-2.318.427-.324.932-.502 1.447-.579V5.5a.75.75 0 0 1 .75-.75"
      clipRule="evenodd"
    />
  </svg>
);
export const DollarCircleIcon = forwardRef(SvgDollarCircle);
